import React from 'react';
import { Link } from 'react-router-dom';

import { LazyImg } from 'src/hooks/useLazyLoad';
import { ProductMainPrice } from 'src/components/ProductDetail/ProductMainPrice';
import { ProductLabelsList } from 'src/components/ProductLabels/ProductLabelsList';
import { ItemExtras } from 'src/components/CategoryListingPage/ItemExtras';
import { EnergyRatingLabel } from 'src/components/EnergyRatingLabel/EnergyRatingLabel';

import classes from './ProductList.scss';
import { ProductListItem } from './utils/productListItem';

export type Product = {
    product: ProductListItem;
    gtmSelectItem(index: number): void;
    index: number;
    onClick?();
    openLinkInNewTab?: boolean;
    variant?: 'search';
};

export function ProductItem(props: Product) {
    const { product, gtmSelectItem, index, onClick } = props;
    const hasLabels = product.labels.length > 0;

    return (
        <div className={classes.content} data-variant={props.variant}>
            <Link
                to={product.url}
                className={classes.image}
                onClick={() => {
                    gtmSelectItem(index);
                    if (onClick) onClick();
                }}
            >
                <LazyImg src={product.small_image.url} alt={product.small_image.label} />
            </Link>
            {hasLabels && (
                <ProductLabelsList
                    className={classes.productListItemLabels}
                    direction="column"
                    labels={product.labels}
                />
            )}
            <div className={classes.productListInfo}>
                <Link
                    to={product.url}
                    onClick={() => {
                        if (onClick) onClick();
                    }}
                    className={classes.productListTitle}
                    target={props.openLinkInNewTab ? '_blank' : undefined}
                >
                    {product.name}
                </Link>
                <span className={classes.productListPrice}>
                    <ProductMainPrice price={product.price} vat={true} layout="grid" />
                </span>
                {product.energy_rating && (
                    <EnergyRatingLabel
                        specificationLink={product.product_fiche_image}
                        energyLabelImage={product.energy_label_image}
                        letter={product.energy_rating}
                        variant="category-list"
                    />
                )}
                <ItemExtras product={product} />
            </div>
        </div>
    );
}

ProductItem.defaultProps = {
    openLinkInNewTab: false,
};
